import React, { useEffect, useState } from "react"
import {
  BodyContainer,
  ModalHeader,
  Title,
  ButtonContainer,
  Link,
  ModalWrapper,
} from './TrovaPopUp.style';
import { Modal } from "@vfit/shared/atoms";
import { getBodyModalHeight } from "./modal.utils";
import { tracking, useTracking } from "@vfit/shared/data-access";


const TrovaModal = (props) => {
  const { trackView } = useTracking({ event: ["view"] });
  
  const [height, setHeight] = useState(500);
  const {
    data: {
      title = " ",
      instructionsInformation = [] }, onCloseModal, show } = props

  useEffect(() => {
    if (show) {
      setHeight(getBodyModalHeight("body-container"))
      trackView({
        event_name: "view",
        event_category: "sales",
        event_label_track: "popup:snodo-agente-negozio",
        page_name_override: "find-agent-popup",
        channel: "business",
        page_section: "eshop",
        page_subsection: "popup",
        page_type: "trova agente-negozio",
        page_environment: "Web",
        journey_name: "trova agente-negozio",
        journey_type: "journey",
      });
    }
  }, [show])

  return (

    <Modal
      show={show}
      height={height}
      onCloseModal={onCloseModal}>
      <ModalWrapper>
        <BodyContainer id={"body-container"}>
          <ModalHeader dangerouslySetInnerHTML={{ __html: title }} />
          {instructionsInformation?.map((item, index) => {
            const { title, button: { isBlank = "true", label = "", link = "" } } = item;
            return (
              <React.Fragment key={index}>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
                <ButtonContainer>
                  <a
                    data-name={`find-agent-popup-cta-${index}`}
                    target={isBlank === "true" ? "_blank" : undefined}
                    href={`${link}?icmp=ebu_web-aziende_popup-agente-negozio`}  // Add `icmp` parameter
                    className="modal-link"
                    onClick={() => {
                      tracking({
                        event_name: ["ui_interaction"],
                        event_category: "users",
                        event_action: "click",
                        // event_label: `find-agent-popup:${label.toLowerCase().replace(/\s/g, "-")}`,
                        link_name: `find-agent-popup:${label.toLowerCase().replace(/\s/g, "-")}`,
                      },'link');
                    }}
                  >
                    {label}
                  </a>
                </ButtonContainer>

              </React.Fragment>
            );
          })}
        </BodyContainer>
      </ModalWrapper>

    </Modal>

  )
}

export default TrovaModal;